import React, { Component } from 'react';
import './Footer.css';
import { Table } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Table hover>
          <tbody>
          <tr>
            <td>Huopalahdentie 6 B 25</td>
          </tr>
          <tr>
            <td>00330 Helsinki</td>
          </tr>
          <tr>
            <td>
              CEO Prof. OTT, VT Rainer Oesch
            </td>
          </tr>
          <tr>
            <td>
            raineroesch@gmail.com / rainer.oesch@helsinki.fi
            </td>
          </tr>
          <tr>
            <td>
              +358 40 5800 811
            </td>
          </tr>
          <tr>
            <td>
              iplaw.fi
            </td>
          </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default Footer;
