import React, { Component } from 'react';
import './fonts.css'
import Header from './components/Header';
import Content from "./components/Content";

import './App.css';
import Footer from "./components/Footer";
import {  BrowserRouter as Router, Route } from "react-router-dom";
import Content_en from "./components/Content_en";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <Route exact path="/" component={Content} />
          <Route exact path="/en" component={Content_en} />
          <Footer/>
        </div>
      </Router>

    );
  }
}

export default App;
