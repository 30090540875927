import React, { Component } from 'react';
import './Content.css';
import { Col, Container, Image, Jumbotron, ListGroup, Row } from "react-bootstrap";


class Content_en extends Component {
  render() {
    return (
      <div className="content">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <Image src={require('../images/Oesch_mid.jpg')} fluid rounded/>
            </Col>
            <Col xs={12} md={6}>
              <div className="expertise-list">
                <h3 className="HeadingText">EXPERTISE</h3>
                <ListGroup className="expertise-items">
                  <ListGroup.Item>
                    Intellectual Property (Industrial Property and Copyright)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Patents, trade secrets, trademarks, designs and unfair competition
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Consulting and advising in IP
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Presentations and expert opinions in IP
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Contracts in IP Law
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Copyright and trademark licensing (franchising)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Contracts in research
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Technology transfer, IT –licensing, Know how-agreements
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Trade secrets (know how agreements)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    IPR –strategies (esp. for companies and organizations)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Publishing agreements (books, music, entertainment)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Goodwill agreements (rights of publicity, trademarks)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Arbitration and IPR’s
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Jumbotron fluid className="expertise-desc bluebox">
                <p>
                  Juris Doctor Rainer Oesch has acted as a professor (commercial law) for several years at the
                  University of  Helsinki, Faculty of Law. He has written several books and articles in the field (patents, copyright,
                  trademarks). He is specialized in intellectual property, industrial property and copyright. Besides his
                  academic tasks he has been active in practice like in consulting for leading law firms, giving legal opinions
                  and presentations for companies and organizations. He has had several positions of trust –both national
                  and international - in organizations (e.g. professional associations in industrial property and copyright,
                  copyright organizations in literary field, also as an advisor for the public sector e.g. for the government and
                  the parliament).
                </p>
              </Jumbotron>
              <p>
                <a
                  href="https://tuhat.helsinki.fi/portal/en/persons/rainer-oesch(3a377605-5167-435b-bd08-01bfe5cdf550).html"
                  target="_blank" rel="noopener noreferrer">Research</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content_en;
