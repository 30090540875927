import React, { Component } from 'react';
import './Content.css';
import { Col, Container, Image, Jumbotron, ListGroup, Row, Tab, Tabs } from "react-bootstrap";


class Content extends Component {
  render() {
    return (
      <div className="content">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <Image src={require('../images/Oesch_mid.jpg')} fluid rounded/>
            </Col>
            <Col xs={12} md={6}>
              <div className="expertise-list">
                <h3 className="HeadingText">ASIANTUNTEMUS & TOIMINTA-ALUEET</h3>
                <ListGroup className="expertise-items">
                  <ListGroup.Item>
                    Immateriaalioikeudet (teollis- ja tekijänoikeudet)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Lausuntoja ja konsultointia immateriaalioikeusasioissa
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Koulutusta ja esityksiä yrityksille ja organisaatioille
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Patentit, liikesalaisuudet, tavaramerkit, mallit, sopimaton menettely elinkeinotoiminnassa
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Tekijänoikeudet
                  </ListGroup.Item>
                  <ListGroup.Item>
                    IPR-strategiat
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Sopimukset - kirjallisuus, musiikki, viihde
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Tutkimussopimukset
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Sopimukset - teknologia- ja it-ala
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Franchising- ja tavaramerkkilisenssit
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Suojakeinot
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Välimiesmenettely - IPR- ja sopimusasioissa
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <Jumbotron fluid className="expertise-desc bluebox">
                  <p>
                    OTT VT Rainer Oesch on ollut pitkään HY:n kauppaoikeuden professori, joka on erikoistunut
                    immateriaalioikeuksiin, teollis- ja tekijänoikeuksiin. Yliopistotehtävän lisäksi hän on luennoinut
                    ja konsultoinut aineettomista oikeuksista yrityksille ja muille organisaatioille. Hänellä on alan
                    luottamustehtäviä organisaatioissa – kansallisissa ja kansainvälisissä (tekijän- ja
                    teollisoikeudet). Hän on toiminut myös asiantuntijana viranomaisille – mm. eduskunta ja ministeriöt.
                    Hän on myös IP Law Consulting Oy:n toimitusjohtaja. Kielet: suomi, ruotsi, englanti ja saksa.
                  </p>
                </Jumbotron>
                <p>
                  <a
                    href="https://tuhat.helsinki.fi/portal/en/persons/rainer-oesch(3a377605-5167-435b-bd08-01bfe5cdf550).html"
                    target="_blank" rel="noopener noreferrer">Julkaisut</a>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs className="cvtab" defaultActiveKey="pro">
                <Tab className="tabitem" eventKey="pro" title="Tausta">
                    <ListGroup className="cv-list">
                      <ListGroup.Item>
                       - professori (kauppaoikeus), Helsingin yliopisto, oikeustieteellinen tiedekunta
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - OTT, VT
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - erikoisala teollis- ja tekijänoikeudet (immateriaalioikeus)
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - nimitys kauppaoikeuden professoriksi 2006
                      </ListGroup.Item>
                      <ListGroup.Item>
                        - akateeminen yrittäjä
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - ennen nimitystä vs. professorina (HY)(kauppaoikeus) 2003-2004, sekä Suomen Akatemian projektin ”Immateriaalioikeudet insolvenssissa” – 2002-2004 johtajana
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - yliopiston opettajan ja tutkijan tehtäviä (mm. yliassistentti, apulaisprofessori);  vierailevana tutkijana Max Planck Institute of Innovation and Competition ja eräissä muissa ulkomaisissa tutkimuslaitoksissa ja yliopistoissa eri vaiheissa 1990-2000
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - Svenska Handelshögskolan – tf. professor i handelsrätt 2000-2001
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - jäsenenä 1994-2000 EU:n komission tekijänoikeuden asiantuntijatyöryhmässä
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - Suomen teollisoikeudellinen yhdistys ry. – hallituksen jäsen (2009 – 2018)
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - Liiketapalautakunta (Keskuskauppakamari) – varapuheenjohtaja (2015-2017)
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - Tutkimuseettinen neuvottelukunta (TenK) – jäsen 2016-2018
                      </ListGroup.Item>
                      <ListGroup.Item>
                       - osallistunut Keskuskauppakamarin välimieskoulutukseen 2017-2018
                      </ListGroup.Item>
                    </ListGroup>
                </Tab>
                <Tab className="tabitem" eventKey="tasks" title="Luott.tehtävät">
                  <ListGroup className="cv-list">
                    <ListGroup.Item>
                      - Tekijänoikeusneuvosto (Opetus- ja kulttuuriministeriö) -  varapuheenjohtaja
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Tekijänoikeuden tietokeskus (johtoryhmän jäsen)
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Suomen tekijänoikeudellinen yhdistys ry  - hallituksen jäsen
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Suomen tietokirjailijat ry. – hallituksen jäsen
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Sanasto ry – hallituksen jäsen
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - K.L. Oesch – muistoyhdistys ry. – hallituksen jäsen
                    </ListGroup.Item>
                  </ListGroup>
                </Tab>
                <Tab className="tabitem"  eventKey="documents" title="Päätuotanto">
                  <ListGroup className="cv-list">
                    <ListGroup.Item>
                      - Oikeus valokuvaan – tutkimus valokuvan suojan kehityksestä ja sisällöstä (1993) (väitöskirja)
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Copyright and Photographs (1999) (Kluwer) yhdessä Gendreaun ja Nordemannin kanssa
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Patenttioikeus – keksintöjen suoja (1. p. 2003) (2. p. 2008) (3. p. 2014) yhdessä Pihlajamaan kanssa; 3. p. lisäksi Sunilan kanssa
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Tavaramerkki (1. p. 2001, 2. p. 2008) yhdessä Salmen, Häkkäsen ja Tommilan kanssa
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Mallioikeus – muotoilun suoja (2005) yhdessä Rinkinevan, Hietamiehen ja Puustisen kanssa
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - kokoomateosten toimittajana ja artikkelin kirjoittajana mm. teokset: IPR-sopimukset, kilpailu ja suojan toteuttaminen  (2007), Tekijänoikeus ja digitaalitalous (2009), Immateriaalioikeudet ja yleinen etu (2017)
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - artikkeleita oikeustieteellisissä julkaisuissa yht. n. 90 kpl
                    </ListGroup.Item>
                  </ListGroup>
                </Tab>
                <Tab className="tabitem" eventKey="presentations" title="Esityksiä">
                  <ListGroup className="cv-list">
                    <ListGroup.Item>
                      - Esityksiä ja luentoja teollis- ja tekijänoikeuksista lakimiesyhdistyksissä ja organisaatioissa, vuonna 2017 - 2019
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - HY:ssä  aiheesta ”Kustannussopimukset nykyisin”. Tietokirjallisuuden kurssi. 2018-2019.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Vuonna 2016 mm. Oulu ja Tampere – lakimiesyhdistykset – aiheesta ”Tekijänoikeudellinen hyvitysvelvollisuus ja ns. kieltokirjeet”.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Vuonna 2016: Patenttitoimisto Papula Nevinpat: Tavaramerkki – erottamiskyky tapausten valossa (huhtikuu 2016).
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Vuonna 2017 Tekijänoikeudet ja sopimukset. Luento (4 h) kurssilla toiminta tietokirjailijana (prof. Hiidenmaan kurssi).
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Vuonna 2017 Immateriaalioikeuksien hyödyntäminen: lisenssisopimukset (2 h) Aalto Pro. Kurssi teollisuus – tekniikka – immateriaalioikeudet (ns. pitkä patenttikurssi).
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Vuonna 2018: Tietokantojen tekijänoikeudellinen suoja ja tietokantasopimukset (helmikuu 2018) (saksaksi; Berliini, Humboldt Universität)
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Nordic Learnings from Cases of Copying: Applied Arts and Copyright (huhtikuu 2018) (Unkari; Szeged University)
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Nordic Learnings from Cases of Copying: Applied Arts and Copyright (4.6.2018). IPR Summer School ; Helsinki.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Tekijänoikeuksien tilanne 2018. Mikä on ongelma – mikro- vai makrotaso? Tietokirjailijat ry:n strategiapäivä. 14.8.2018
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Immateriaalioikeudet ja yritysnäkökulma. Immateriaalioikeuksien hyödyntäminen - lisenssit keinona yrityksen kasvuun ja menestykseen. 9.10.2018
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Tekoäly ja tekijänoikeudet. Tampere Smart City 10.10.2018.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - IPR Strategy for a Start up. Helsinki Terkko Health Hub. 31.1.2019.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      - Innovaatiotoiminta ja uusi liikesalaisuuslaki. Yliopistojen ja korkeakoulujen lakimiesten tapaaminen 4.4.2019 Levi.
                    </ListGroup.Item>
                  </ListGroup>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content;
