import React, { Component } from 'react';
import './Header.css';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import { Form, Image } from "react-bootstrap";


class Header extends Component {
  render() {
    return (
      <div className="Header">
        <Navbar variant="light" className="justify-content-between mainnav" >
          <Navbar.Brand href="#home" className="logo"><h2 className="HeadingText">IP LAW CONSULTING OY</h2></Navbar.Brand>
          <Form inline className="flagholder">
            <Nav.Link href="/" className={"lang-flag"}>
              <Image className="lang-flag" src={require('../images/fi.svg')} />
            </Nav.Link>
            <Nav.Link href="/en" className={"lang-flag"}>
              <Image className="lang-flag" src={require('../images/gb.svg')} />
            </Nav.Link>
          </Form>
        </Navbar>
      </div>
    );
  }
}

export default Header;
